<template>
  <jc-form class="jc-datashow-list jc-datashow-list-inline">
    <jc-row :gutter="20">
      <jc-col :span="8">
        <jc-form-item label="主办单位全称：">
          <p>
            {{ mainInfoData && mainInfoData.organizersName | limiteStr(50) }}
          </p>
        </jc-form-item>
      </jc-col>
      <jc-col :span="8">
        <jc-form-item label="主办单位性质：">
          <p>
            {{ mainInfoData && mainInfoData.organizersNature | organizersNatureFilter(organizersNatureOptions) }}
          </p>
        </jc-form-item>
      </jc-col>
      <jc-col :span="8">
        <jc-form-item label="证件类型：">
          <p>
            {{ mainInfoData && mainInfoData.identityType | identityTypeFilter(identityTypeOptions) }}
          </p>
        </jc-form-item>
      </jc-col>
      <jc-col :span="8">
        <jc-form-item label="证件号码：">
          <p>{{ mainInfoData && mainInfoData.identityNo | limiteStr(50) }}</p>
        </jc-form-item>
      </jc-col>
      <jc-col :span="8">
        <jc-form-item label="手机号码：">
          <p>{{ mainInfoData && mainInfoData.mobile | limiteStr(50) }}</p>
        </jc-form-item>
      </jc-col>
      <jc-col :span="8">
        <jc-form-item label="应急电话：">
          <p>
            {{ mainInfoData && mainInfoData.emergencyCall | limiteStr(50) }}
          </p>
        </jc-form-item>
      </jc-col>
      <jc-col :span="8">
        <jc-form-item label="电子邮件地址：">
          <p>{{ mainInfoData && mainInfoData.email | limiteStr(50) }}</p>
        </jc-form-item>
      </jc-col>
      <jc-col :span="8">
        <jc-form-item label="主办单位所属区域：">
          <p>
            {{
              mainInfoData &&
                `${mainInfoData.provinceName}${mainInfoData.cityName}${mainInfoData.countyName}` | limiteStr(50)
            }}
          </p>
        </jc-form-item>
      </jc-col>
      <jc-col :span="8">
        <jc-form-item label="主办单位证件地址：">
          <p>
            {{ mainInfoData && mainInfoData.indentityAddress | limiteStr(50) }}
          </p>
        </jc-form-item>
      </jc-col>
      <jc-col :span="8">
        <jc-form-item label="主办单位通信地址：">
          <p>
            {{ mainInfoData && mainInfoData.detailAddress | limiteStr(50) }}
          </p>
        </jc-form-item>
      </jc-col>
      <jc-col :span="8">
        <jc-form-item label="主体备注信息：">
          <p>{{ mainInfoData && mainInfoData.remark | limiteStr(50) }}</p>
        </jc-form-item>
      </jc-col>
    </jc-row>
  </jc-form>
</template>
<script>
import { filterArray } from '@/utils'
import profileMixins from '@/mixins/profile.mixins.js'
export default {
  props: {
    infoData: Object
  },
  components: {},
  mixins: [profileMixins],
  data() {
    return {}
  },
  watch: {},
  computed: {
    mainInfoData() {
      if (this.infoData) return this.infoData.principalInfo
    },
    organizersNature() {
      if (this.infoData) {
        let data = filterArray(
          this.mainInfoData.organizersNature,
          JSON.parse(this.infoData.recordOrganizersNatureEnums),
          'code'
        )
        return data.name
      }
    },
    identityType() {
      if (this.infoData) {
        let data = filterArray(
          this.mainInfoData.identityType,
          JSON.parse(this.infoData.recordIdentityTypeEnums),
          'code'
        )
        return data.name
      }
    }
  },
  mounted() {},
  created() {},
  filters: {},
  methods: {}
}
</script>
